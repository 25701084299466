import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
// import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import Conf from "../Conf";
import IconButton from "@mui/material/IconButton";

import {
  prefixs,
  student_prefixs,
  jobs,
  months,
  promotion_media,
  influence_factors,
} from "../const";
import LinearProgress from "@mui/material/LinearProgress";
import { GridInputItem, GridSelectItem } from "../components/InputForm";
import { getStampTime } from "../js/main";
// import DateFnsUtils from "@date-io/date-fns";
import { Link } from "react-router-dom";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function VisitForm(props) {
  const [form, setForm] = useState({
    prefix: "",
    curator_prefix: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [days, setDays] = useState([]);
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(false);

  const [influences, setInfluences] = useState(influence_factors);
  const [medias, setMedias] = useState(promotion_media);
  const styles = {
    groupHead: {
      paddingBottom: "20px",
      fontSize: "1rem",
      fontWeight: 400,
      marginTop: "20px",
    },
    subGroupHeader: {
      color: "#888",
      fontSize: ".9rem",
      paddingLeft: "15px",
    },
  };
  useEffect(() => {
    var r = [];
    for (let i = 1; i < 32; i++) r.push({ value: i, label: i });
    setDays(r);
    var dt = new Date();
    dt = dt.getFullYear() + 543;
    r = [];
    for (let i = dt; i > dt - 20; i--) r.push({ value: i - 543, label: i });
    setYears(r);
    setInfluences(influence_factors);
    setMedias(promotion_media);
  }, []);
  useEffect(() => {
    async function getRegister() {
      setLoading(true);
      /*
      let result = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        { collection: "registers", match: { _id: props.register_id } },
        {
          headers: { Authorization: cookie.access_token || cookie.otp },
        }
      );
      */
      let result = await axios.get(
        Conf.endpoint.tonkla + "/ca/visit/" + props.register_id,
        {
          headers: { Authorization: props.otp || cookie.access_token },
        }
      );
      if (result.data && !result.data.error) {
        result = result.data;
        let arrDate = [];
        if (result.birth_date) arrDate = result.birth_date.split("-");
        if (arrDate.length === 3) {
          result.birth_day = parseInt(arrDate[2]);
          result.birth_month = parseInt(arrDate[1]);
          result.birth_year = arrDate[0];
        }
        for (let i = 0; i < promotion_media.length; i++) {
          promotion_media[i].checked = false;
        }
        for (let media of result.promotion_media) {
          let i = promotion_media.findIndex((j) => j.value === media);
          promotion_media[i].checked = false;
          if (i > -1) promotion_media[i].checked = true;
        }
        for (let i = 0; i < influence_factors.length; i++) {
          influence_factors[i].checked = false;
        }
        for (let factor of result.influence_factors) {
          let i = influence_factors.findIndex((j) => j.value === factor);
          if (i > -1) influence_factors[i].checked = true;
        }
        setForm(result);
      }

      setLoading(false);
    }

    if (props.register_id && form._id !== props.register_id) getRegister();
  }, [props, form]);
  function changeValue(e) {
    var id = e.target.id;
    var value = e.target.value || "";
    setForm({ ...form, [id]: value });
  }
  async function zipSearch(e) {
    let result = await axios.get(
      Conf.endpoint.tonkla + "/ca/zip/" + e.target.value
    );
    if (result.data && result.data.zip_code) {
      result = result.data;
      setForm({
        ...form,
        province: result.province,
        district: result.district,
      });
    }
  }
  async function save() {
    form.promotion_media = medias
      .filter((j) => j.checked === true)
      .map((media) => media.value);
    form.influence_factors = influences
      .filter((j) => j.checked === true)
      .map((influence) => influence.value);
    form.birth_date =
      form.birth_year +
      "-" +
      String(form.birth_month).padStart(2, "0") +
      "-" +
      String(form.birth_day).padStart(2, "0");
    if (!form.visit_date || form.visit_date === "") {
      form.visit_date = await getStampTime();
      form.visit_date = form.visit_date.iso_date;
    }
    var send = JSON.parse(JSON.stringify(form));

    // Check Require
    var requires = [
      "contact_prefix",
      "contact_first_name",
      "contact_last_name",
      "prefix",
      "first_name",
      "last_name",
      "nickname",
      "email",
      "birth_day",
      "birth_month",
      "birth_year",
      "phone",
      "relation",
      "job",
      "workplace",
      "house_number",
      "village",
      "moo",
      "zip_code",
      "area",
      "district",
      "province",
    ];
    for (let req of requires) {
      let Class = document.getElementById(req).getAttribute("class");
      if (form[req] && form[req] !== "") {
        Class = Class.replaceAll("error-text-field", "");
        document.getElementById(req).setAttribute("class", Class);
        requires = requires.filter((j) => j !== req);
      } else {
        Class += " error-text-field";
        document.getElementById(req).setAttribute("class", Class);
      }
    }
    if (requires.length > 0) {
      alert("Please fill complete information");
    } else {
      let result = await axios.put(
        Conf.endpoint.tonkla + "/ca/register",
        send,
        {
          headers: { Authorization: props.otp || cookie.access_token },
        }
      );
      if (result.data.error) alert(result.data.error.message);
      else {
        if (props.onSaveCompleted) {
          props.onSaveCompleted(result.data);
        } else alert("Save completed");
      }
    }
  }
  // function reset() {
  //   window.location = process.env.PUBLIC_URL + "/form/visit";
  // }
  function sendMail() {
    setOpenDialog(true);
  }
  async function cancelVisit() {
    if (!form.status_date || form.status_date.length === 0) {
      if (window.confirm("Confirm to cancel")) {
        let result = await axios.delete(
          Conf.endpoint.tonkla + "/ca/delete/" + form._id,
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        if (result.data.error) alert(result.data.error.message);
        else alert("Cancel completed");
      }
    }
  }
  function changeVisitForm(key, field, value) {
    let visits = JSON.parse(JSON.stringify(form.visit_schedules));
    visits[key][field] = value;
    setForm({ ...form, visit_schedules: visits });
  }
  async function waiver() {
    let cancel = "";
    let status = form.waiver;
    if (!status) status = true;
    else status = !status;
    if (status === false) cancel = "cancel";

    if (window.confirm("Confirm to " + cancel + " waiver")) {
      let result = await axios.put(
        Conf.endpoint.tonkla + "/ca/visit/waiver/" + props.register_id,
        { status: status },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      if (result.data.success && result.data.success === true) {
        alert(cancel + " waiver completed");
        setForm({ ...form, waiver: status });
      }
    }
  }
  function removeVisitSchedule(id) {
    let visits = [...form.visit_schedules];
    visits = visits.filter((j) => j._id !== id);
    setForm({ ...form, visit_schedules: visits });
  }
  return (
    <div style={{ paddingTop: "15px" }}>
      {form && (
        <SendMailDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          form={form}
        />
      )}
      {loading === true && <LinearProgress />}
      <Grid
        container
        component={Paper}
        spacing={1}
        style={{ padding: "15px 15px" }}
      >
        <Grid item xs={12} align="center" style={{ paddingBottom: "10px" }}>
          <h2 style={{ marginBottom: "5px", fontWeight: 400 }}>
            Survey for Tonkla School Visitor
          </h2>
          <div>(แบบสอบถามเยี่ยมชม)</div>
        </Grid>

        <Grid item xs={12} style={styles.groupHead}>
          1. Visitor Information{" "}
          <div style={styles.subGroupHeader}>(ข้อมูลผู้เยี่ยมชม)</div>
        </Grid>
        <>
          <GridSelectItem
            size={[12, 2, 2, 2]}
            label="Prefix"
            id="contact_prefix"
            value={form.contact_prefix}
            changeValue={changeValue}
            options={prefixs}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="Contact First Name"
            placeholder="ชื่อ ผู้มาติดต่อ"
            id="contact_first_name"
            value={form.contact_first_name}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="Contact Last Name"
            placeholder="นามสกุล ผู้มาติดต่อ"
            id="contact_last_name"
            value={form.contact_last_name}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[6, 6, 3, 3]}
            label="House Number"
            placeholder="บ้านเลขที่"
            id="house_number"
            value={form.house_number}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[6, 6, 3, 3]}
            label="Village"
            placeholder="หมู่บ้าน"
            id="village"
            value={form.village}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[6, 6, 3, 3]}
            label="Moo"
            placeholder="หมู่"
            id="moo"
            value={form.moo}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[6, 6, 3, 3]}
            label={
              <div>
                Zip code <Icon style={{ fontSize: ".9rem" }}>search</Icon>
              </div>
            }
            placeholder="รหัสไปรษณีย์"
            id="zip_code"
            value={form.zip_code}
            changeValue={changeValue}
            enterValue={(e) => zipSearch(e)}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="Sub district"
            placeholder="ตำบล"
            id="area"
            value={form.area}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="District"
            placeholder="อำเภอ"
            id="district"
            value={form.district}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="Province"
            placeholder="จังหวัด"
            id="province"
            value={form.province}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="Mobile Number"
            placeholder="โทร"
            id="phone"
            value={form.phone}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 12, 12, 12]}
            label="Domicile/Habitation"
            placeholder="ภูมิลำเนา"
            id="hometown"
            value={form.hometown}
            changeValue={changeValue}
          />

          <GridSelectItem
            size={[12, 6, 6, 6]}
            label="Career"
            id="job"
            value={form.job}
            changeValue={changeValue}
            options={jobs}
          />
          <GridInputItem
            size={[12, 6, 6, 6]}
            label="Workplace"
            placeholder="สถานที่ทำงาน"
            id="workplace"
            value={form.workplace}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 6, 6, 6]}
            label="e-Mail"
            placeholder="e-Mail"
            id="email"
            value={form.email}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 6, 6, 6]}
            label="Line ID"
            placeholder="Line ID"
            id="line_id"
            value={form.line_id}
            changeValue={changeValue}
          />
        </>
        <Grid item xs={12} style={styles.groupHead}>
          2. Student Information
          <div style={styles.subGroupHeader}>(ข้อมูลนักเรียน)</div>
        </Grid>
        <>
          <GridSelectItem
            size={[12, 2, 2, 2]}
            label="Prefix"
            id="prefix"
            value={form.prefix}
            changeValue={changeValue}
            options={student_prefixs}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="Student's name"
            placeholder="ชื่อนักเรียน"
            id="first_name"
            value={form.first_name}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="Student's surname"
            placeholder="ชื่อนักเรียน"
            id="last_name"
            value={form.last_name}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 12, 6, 6]}
            label="Student's nickname"
            placeholder="ชื่อเล่น"
            id="nickname"
            value={form.nickname}
            changeValue={changeValue}
          />

          <GridSelectItem
            size={[12, 4, 2, 2]}
            label="Birth day"
            id="birth_day"
            value={form.birth_day}
            changeValue={changeValue}
            options={days}
          />
          <GridSelectItem
            size={[12, 4, 2, 2]}
            label="Birth month"
            id="birth_month"
            value={form.birth_month}
            changeValue={changeValue}
            options={months}
          />
          <GridSelectItem
            size={[12, 4, 2, 2]}
            label="Birth year"
            id="birth_year"
            value={form.birth_year}
            changeValue={changeValue}
            options={years}
          />
          <GridSelectItem
            size={[12, 6, 6, 6]}
            label="Relation with visitor"
            id="relation"
            value={form.relation}
            changeValue={changeValue}
            options={[
              { value: "บิดา", label: "บิดา (Father)" },
              { value: "มารดา", label: "มารดา (Mother)" },
              { value: "อื่น ๆ", label: "อื่น ๆ (Other)" },
            ]}
          />

          <GridInputItem
            size={[12, 6, 6, 6]}
            label="Other Ralation"
            placeholder="ความสัมพันธ์อื่น ๆ"
            id="other_relation"
            value={form.other_relation}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 12, 12, 12]}
            label="Old School Name"
            placeholder="ชื่อโรงเรียนเก่า"
            id="old_school"
            value={form.old_school}
            changeValue={changeValue}
          />
        </>
        <Grid item xs={12} style={styles.groupHead}>
          3. Where did you find the information about our school ?
          <div style={styles.subGroupHeader}>
            (ท่านรู้จักโรงเรียนต้นกล้า
            หรือได้รับข่าวสารของโรงเรียนจากแหล่งข้อมูลใด ?)
          </div>
        </Grid>
        <Grid item xs={12}>
          <CheckForm
            checkboxs={medias}
            other={form.promotion_other}
            id="promotion_media"
            onCheck={(key, checked) => {
              let arr = JSON.parse(JSON.stringify(medias));
              arr[key].checked = checked;
              setMedias(arr);
            }}
            onChangeOther={(e) =>
              setForm({ ...form, promotion_other: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} style={styles.groupHead}>
          3. What are the factors that influence your decision about a school
          for your child?
          <div style={styles.subGroupHeader}>
            (ปัจจัยที่มีผลต่อการตัดสินใจเลือกโรงเรียน ?)
          </div>
        </Grid>
        <Grid item xs={12}>
          <CheckForm
            checkboxs={influences}
            other={form.influence_factor_other}
            onCheck={(key, checked) => {
              let arr = JSON.parse(JSON.stringify(influences));
              arr[key].checked = checked;
              setInfluences(arr);
            }}
            onChangeOther={(e) =>
              setForm({ ...form, influence_factor_other: e.target.value })
            }
            id="influence_factors"
          />
        </Grid>
        <Grid item xs={12} style={styles.groupHead}>
          4. ส่วนของเจ้าหน้าที่
        </Grid>
        <Grid item xs={12}>
          4.1 การเยี่ยมชม (Visit Schedules)
        </Grid>
        <Grid item xs={12}>
          <VisitTable
            visits={form.visit_schedules}
            register_id={form._id}
            onChangeInput={(key, field, value) =>
              changeVisitForm(key, field, value)
            }
            onDelete={removeVisitSchedule}
          />
          <Grid item xs={12} style={{ paddingBottom: "10px" }}>
            4.2 ความเห็นอื่น ๆ (Other Note)
          </Grid>
          <GridInputItem
            size={[12, 12, 12, 12]}
            label=""
            placeholder=""
            rows={4}
            id="counselor_note"
            value={form.counselor_note}
            changeValue={changeValue}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon>save</Icon>}
            style={{ minWidth: "160px" }}
            onClick={save}
          >
            Save Form
          </Button>
        </Grid>
        {!props.otp && (
          <Grid item xs={12} sm={8} align="right">
            <Button
              variant="contained"
              color="inherit"
              startIcon={<Icon>print</Icon>}
              style={{ minWidth: "130px", marginRight: "5px" }}
              className="bg_green"
              onClick={() => {
                if (props.register_id) {
                  document.getElementById("printFrame").src =
                    process.env.PUBLIC_URL +
                    "/print/visitform/" +
                    props.register_id;
                }
              }}
            >
              Print
            </Button>
            <Button
              variant="contained"
              color="inherit"
              startIcon={<Icon>mail</Icon>}
              style={{ minWidth: "160px", marginRight: "5px" }}
              className="bg_orange"
              onClick={props.register_id && sendMail}
            >
              <Badge badgeContent={form.send_form_count} color="secondary">
                Register Form
              </Badge>
            </Button>
            {!form.status_date ||
              (form.status_date.length === 0 && (
                <Button
                  variant="contained"
                  color="inherit"
                  startIcon={<Icon>undo</Icon>}
                  style={{
                    minWidth: "160px",
                    marginRight: "5px",
                    background: "#607d8b",
                    color: "#fff",
                  }}
                  onClick={cancelVisit}
                >
                  Cancel Visit
                </Button>
              ))}

            {form.waiver && form.waiver === true ? (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Icon>clear</Icon>}
                style={{ minWidth: "130px" }}
                onClick={props.register_id && waiver}
              >
                Cancel Waiver
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Icon>clear</Icon>}
                style={{ minWidth: "130px" }}
                onClick={props.register_id && waiver}
              >
                Waiver
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      <iframe
        title="printFrame"
        name="printFrame"
        id="printFrame"
        style={{ border: "none", width: "0px", height: "0px" }}
      ></iframe>
    </div>
  );
}

// const allTimeSlot = [
//   "09:00 - 09:30",
//   "09:30 - 10:00",
//   "10:00 - 10:30",
//   "10:30 - 11:00",
//   "11:00 - 11:30",
//   "11:30 - 12:00",
// ];
const allTimeSlot = [
  "08:45 - 09:30",
  "09:30 - 10:15",
  "10:15 - 11:00",
  "11:00 - 11:45",
];
function getAvailiableTimeSlot(dt, register_id, current) {
  return new Promise(async (resolve) => {
    let result = await axios.post(
      Conf.endpoint.tonkla + "/ca/query",
      {
        collection: "registers",
        match: {
          $and: [
            { _id: { $ne: register_id } },
            { "visit_schedules.visit_date": dt },
          ],
        },
        select: "visit_schedules",
      },
      {
        headers: { Authorization: cookie.access_token },
      }
    );
    let timeSlot = allTimeSlot;
    if (result.data) {
      result = result.data.result;
      for (let r of result) {
        for (let v of r.visit_schedules) {
          timeSlot = timeSlot.filter(
            (j) => j !== v.visit_time //|| j === current
          );
        }
      }
    }
    resolve(timeSlot);
  });
}

function VisitTable(props) {
  const [form, setForm] = useState();
  const [state, setState] = useState({
    openDialog: false,
    visit: {},
  });
  const { visits } = props;

  useEffect(() => {
    (async () => {
      if (props && props.register_id) {
        let Form = [...props.visits];
        for (let f of Form) {
          let timeSlot = await getAvailiableTimeSlot(
            f.visit_date,
            props.register_id,
            f.visit_time
          );
          if (timeSlot.indexOf(f.visit_time) < 0) {
            timeSlot.push(f.visit_time);
            timeSlot.sort();
          }
          f.timeSlot = timeSlot;
        }
        setForm(Form);
      }
    })();
  }, [props]);

  function removeVisitSchedule(visit) {
    if (visit.counselor) alert("ไม่สามารถลบข้อมูลการเยี่ยมชมนี้ได้");
    else props.onDelete(visit._id);
  }

  return (
    <>
      {visits && form && (
        <>
          <SendAssessmentMailDialog
            open={state.openDialog}
            onClose={() => setState({ openDialog: false, visit: {} })}
            visit={state.visit}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TableContainer style={{ marginBottom: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "6%" }}
                    >
                      No
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "30%" }}
                    >
                      Visit Date
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "30%" }}
                    >
                      Visit Time
                    </TableCell>
                    <TableCell
                      className="td"
                      align="center"
                      style={{ width: "30%" }}
                    >
                      Counselor
                    </TableCell>
                    <TableCell style={{ width: "4%" }} />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {visits.map((visit, key) => (
                    <TableRow key={key}>
                      <TableCell className="td" align="center">
                        <IconButton
                          color="secondary"
                          style={{ padding: "3px 3px" }}
                          onClick={() => removeVisitSchedule(visit)}
                        >
                          <Icon>clear</Icon>
                        </IconButton>
                      </TableCell>
                      <TableCell className="controlTd" align="center">
                        <DatePicker
                          fullWidth
                          margin="normal"
                          format="YYYY-MM-DD"
                          value={dayjs(visit.visit_date)}
                          onChange={(value) => {
                            value = moment(new Date(value)).format(
                              "YYYY-MM-DD"
                            );
                            props.onChangeInput(key, "visit_date", value);
                          }}
                          style={{ marginTop: "1px", marginBottom: "1px" }}
                          autoOk={true}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              size: "small",
                            },
                          }}
                          inputVariant="outlined"
                        />
                      </TableCell>

                      <TableCell className="controlTd" align="center">
                        <FormControl variant="outlined" size="small" fullWidth>
                          <Select
                            native
                            value={visit.visit_time}
                            style={{ marginTop: "1px", marginBottom: "1px" }}
                            onChange={(e) =>
                              props.onChangeInput(
                                key,
                                "visit_time",
                                e.target.value
                              )
                            }
                          >
                            <option aria-label="None" value="-" />
                            {visit.timeSlot.map((val, key) => (
                              <option key={key}>{val}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className="controlTd" align="center">
                        <FormControl variant="outlined" size="small" fullWidth>
                          <Select
                            native
                            value={visit.counselor}
                            style={{ marginTop: "1px", marginBottom: "1px" }}
                            onChange={(e) =>
                              props.onChangeInput(
                                key,
                                "counselor",
                                e.target.value
                              )
                            }
                          >
                            <option aria-label="None" value="-" />
                            {[
                              "จีรพร จงเจริญ",
                              "สาเกต ทองเที่ยง",
                              "กนกวรรณ์ โปธิปัน",
                              "วนิดา เจริญฉัน",
                            ].map((val, key) => (
                              <option key={key}>{val}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className="controlTd" align="center">
                        <IconButton
                          color="primary"
                          style={{ padding: "3px 3px" }}
                          onClick={() =>
                            setState({ openDialog: true, visit: visit })
                          }
                        >
                          {visit.assessments ? (
                            <Icon style={{ color: "#3b3" }}>done</Icon>
                          ) : (
                            <Icon>launch</Icon>
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </LocalizationProvider>
        </>
      )}
    </>
  );
}

const SendAssessmentMailDialog = (props) => {
  const [form, setForm] = useState({});
  useEffect(() => {
    setForm(props.visit);
  }, [props]);
  async function sendMail() {
    if (form.email && form.email !== "") {
      let send = {
        email: form.email,
        assessment_id: form._id,
        visit_date: form.visit_date,
        visit_time: form.visit_time,
      };
      let result = await axios.post(
        Conf.endpoint.tonkla + "/ca/register/sendvisitassessmentform",
        send,
        { headers: { Authorization: cookie.access_token } }
      );
      console.log(result);
      if (result.data.error) alert(result.data.error.message);
      else {
        alert("Send completed");
        props.onClose();
      }
    }
  }
  return (
    <>
      <Dialog
        maxWidth="sm"
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">แบบประเมินการเยี่ยมชม</DialogTitle>
        <DialogContent style={{ width: "350px" }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", paddingTop: "10px" }}
            component="div"
          >
            <GridInputItem
              size={[12, 12, 12, 12]}
              label="e-Mail"
              placeholder="อีมล"
              id="email"
              value={form.email || ""}
              changeValue={(e) => setForm({ ...form, email: e.target.value })}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            component={Link}
            to={process.env.PUBLIC_URL + "/form/self/assessment/" + form._id}
            target="_blank"
            color="primary"
          >
            open Form
          </Button>
          <Button onClick={sendMail} color="primary">
            Send Mail
          </Button>
          <Button onClick={() => props.onClose()} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SendMailDialog = (props) => {
  const [form, setForm] = useState({});
  useEffect(() => {
    setForm(props.form);
  }, [props]);
  async function sendMail() {
    if (form.email && form.email !== "") {
      let send = {
        email: form.email,
        name: form.prefix + " " + form.first_name + " " + form.last_name,
        register_id: form._id,
      };
      console.log(Conf.endpoint.tonkla + "/ca/register/sendform", send);
      let result = await axios.post(
        Conf.endpoint.tonkla + "/ca/register/sendform",
        send,
        { headers: { Authorization: cookie.access_token } }
      );
      if (result.data.error) alert(result.data.error.message);
      else {
        alert("Send completed");
        props.onClose();
      }
    }
  }
  function copyURL() {
    let txt =
      "https://tca.tonkla.ac.th/form/application/" + form.register_form_token;
    // let txt =
    //   "ใบสมัคร " + form.prefix + " " + form.first_name + " " + form.last_name;
    // txt +=
    //   "\nLink : https://tca.tonkla.ac.th/form/application/" +
    //   form.register_form_token;
    // txt += "\nUsername : " + form.usn_register;
    // txt += "\nPassword : " + form.pwd_register;
    navigator.clipboard.writeText(txt);
  }
  return (
    <>
      <Dialog
        maxWidth="sm"
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ยืนยันการส่งใบสมัคร</DialogTitle>
        <DialogContent style={{ width: "350px" }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
            component="div"
          >
            {form.prefix} {form.first_name} {form.last_name}
            <br />
            <br />
            <GridInputItem
              size={[12, 12, 12, 12]}
              label="e-Mail"
              placeholder="อีเมล"
              id="email"
              value={form.email}
              changeValue={(e) => setForm({ ...form, email: e.target.value })}
            />
            {form.register_form_token &&
              form.usn_register &&
              form.pwd_register && (
                <div>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel>Register URL</InputLabel>
                    <OutlinedInput
                      value={
                        "https://tca.tonkla.ac.th/form/application/" +
                        form.register_form_token
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={() => copyURL()} edge="end">
                            <Icon>content_copy</Icon>
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Register URL"
                    />
                  </FormControl>
                </div>
              )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            component={Link}
            to={process.env.PUBLIC_URL + "/form/register/" + form._id}
            target="_blank"
            color="primary"
          >
            open Form
          </Button>
          <Button onClick={sendMail} color="primary">
            Send Mail
          </Button>
          <Button onClick={() => props.onClose()} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CheckForm = (props) => {
  const [other, setOther] = useState();
  const [checkboxs, setCheckboxs] = useState([]);
  useEffect(() => {
    setOther(props.other || "");
    setCheckboxs(props.checkboxs || []);
  }, [props]);
  function checkItem(e, key) {
    let checked = e.target.checked;
    if (props.onCheck) props.onCheck(key, checked);
  }
  function changeOther(e) {
    if (props.onChangeOther) props.onChangeOther(e);
  }
  return (
    <div style={{ paddingLeft: "10px" }}>
      <Grid container>
        {checkboxs.map((ch, key) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ch.checked || false}
                  onChange={(e) => checkItem(e, key)}
                  color="primary"
                />
              }
              label={<div>{ch.label}</div>}
            />
          </Grid>
        ))}
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <GridInputItem
            size={[12, 12, 12, 12]}
            label="Other"
            placeholder="อื่น ๆ"
            id={props.id + "_other"}
            value={other}
            changeValue={(e) => changeOther(e)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
