import React from "react";
import "../print/styles.css";

export const FieldLabel = ({ attr }) => {
  return (
    <div style={{ display: "inline-block" }}>
      <div
        style={{
          fontSize: "11pt",
          //   border: "1px solid #ccc",
          width: attr.width,
          textAlign: attr.align || "left",
        }}
        className="sarabun"
      >
        {attr.label}
      </div>
      {attr.alt_label && (
        <div
          style={{
            fontWeight: "300",
            color: "#666",
            fontSize: "9pt",
            //   border: "1px solid #ccc",
            width: attr.width,
            textAlign: attr.align || "left",
          }}
          className="sarabun"
        >
          {attr.alt_label}
        </div>
      )}
    </div>
  );
};

export const FieldValue = ({ attr }) => {
  return (
    <div
      style={{
        fontSize: "11pt",
        fontWeight: "300",
        borderBottom: "1px dotted #999",
        borderWidth: "thin",
        width: attr.width,
        height: "25px",
        display: "inline-block",
        paddingTop: ".2cm",
        paddingLeft: ".1cm",
        paddingRight: ".1cm",
        textAlign: attr.align || "left",
        fontFamily: "Sarabun",
      }}
      className="sarabun"
    >
      {attr.label || ""}
    </div>
  );
};
