import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Conf from "../Conf";
import axios from "axios";
import { Stack } from "@mui/system";
import { Grid } from "@mui/material";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Pickup() {
  const params = useParams();
  const [register, setRegister] = useState();
  const [pickupPersons, setPickupPersons] = useState();
  useEffect(() => {
    (async () => {
      if (params && params.id) {
        let result = await axios.get(
          Conf.endpoint.tonkla + "/ca/visit/" + params.id,
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        if (result.data && result.data._id) setRegister(result.data);
      }
    })();
  }, [params]);
  useEffect(() => {
    (async () => {
      if (register) {
        let pickupPersons = await axios.post(
          Conf.endpoint.tonkla + "/ca/query",
          {
            collection: "pickup_persons",
            match: { register_id: register._id },
            sort: { priority: 1 },
          },
          { headers: { Authorization: cookie.access_token } }
        );
        pickupPersons = await pickupPersons.data.result.filter(
          (j) => j.disabled !== true
        );
        setPickupPersons(pickupPersons);
      }
    })();
  }, [register]);

  useEffect(() => {
    if (pickupPersons) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, [pickupPersons]);

  return (
    <div>
      {register && (
        <div
          style={{
            width: "21.5cm",
            padding: "2cm .6cm",
            paddingTop: "0cm",
            margin: "auto",
          }}
          className="sarabun"
        >
          <div
            style={{ fontSize: "18pt", textAlign: "center" }}
            className="bold sarabun"
          >
            ข้อมูลนักเรียนและผู้ปกครอง สำหรับทำบัตรนักเรียน
          </div>
          <div
            style={{ fontSize: "14pt", textAlign: "center" }}
            className="sarabun"
          >
            Student and Parents Information for Student Card
          </div>
          <StudentInfo register={register} />
          {pickupPersons && (
            <>
              <PickupImg pickups={pickupPersons} />
              <PickupInfo pickups={pickupPersons} />
            </>
          )}
          <SignArea />
        </div>
      )}
    </div>
  );
}
function SignArea() {
  return (
    <div style={{ textAlign: "right", marginTop: "2.5cm" }}>
      <div
        style={{
          width: "13cm",
          textAlign: "center",
          margin: "0px 0px auto auto",
          fontSize: "14pt",
        }}
      >
        <div className="sarabun">
          ลงชื่อ........................................................................ผู้ให้ข้อมูล
        </div>
        <div
          className="sarabun"
          style={{ paddingRight: "1cm", marginTop: ".3cm" }}
        >
          (........................................................................)
        </div>
        <div
          className="sarabun"
          style={{ paddingRight: "1cm", marginTop: ".3cm" }}
        >
          ........../..................../..........
        </div>
      </div>
    </div>
  );
}

function PickupInfo({ pickups }) {
  return (
    <div style={{ paddingLeft: ".5cm", paddingTop: ".5cm" }}>
      {[0, 1, 2, 3].map((key) => (
        <Stack key={key} direction={"row"} sx={{ marginTop: ".3cm" }}>
          <div
            className="sarabun"
            style={{
              fontSize: "14pt",
              width: "2.5cm",
            }}
          >
            รูปที่ {key + 1} ชื่อ
          </div>

          <div
            className="sarabun"
            style={{
              fontSize: "14pt",
              width: "5cm",
              borderBottom: "1px dotted #111",
              textAlign: "center",
            }}
          >
            {pickups[key] ? pickups[key].first_name : "-"}
          </div>
          <div
            className="sarabun"
            style={{
              fontSize: "14pt",
              width: "2cm",
              textAlign: "center",
            }}
          >
            นามสกุล
          </div>

          <div
            className="sarabun"
            style={{
              fontSize: "14pt",
              width: "5cm",
              borderBottom: "1px dotted #111",
              textAlign: "center",
            }}
          >
            {pickups[key] ? pickups[key].last_name : "-"}
          </div>
          <div
            className="sarabun"
            style={{
              fontSize: "14pt",
              width: "2.8cm",
            }}
          >
            ความสัมพันธ์
          </div>

          <div
            className="sarabun"
            style={{
              fontSize: "14pt",
              width: "3.5cm",
              borderBottom: "1px dotted #111",
              textAlign: "center",
            }}
          >
            {pickups[key] ? pickups[key].relation : "-"}
          </div>
        </Stack>
      ))}
    </div>
  );
}

function PickupImg({ pickups }) {
  const [images, setImages] = useState([]);
  useEffect(() => {
    (async () => {
      let Imgs = [];
      for (let person of pickups) {
        let img = await axios.get(
          Conf.endpoint.fileUpload + "/" + person.photo
        );
        Imgs.push(img.data);
      }
      setImages(Imgs);
    })();
  }, [pickups]);

  return (
    <div style={{ marginTop: ".5cm" }}>
      <div className="sarabun bold" style={{ fontSize: "14pt" }}>
        2. รูปผู้ปกครองสำหรับติดหลังบัตรนักเรียน เพื่อความปลอดภัยในการรับ - ส่ง
        นักเรียน
      </div>
      <div
        className="sarabun bold"
        style={{ paddingLeft: ".5cm", fontSize: "12pt" }}
      >
        Picture of Parents of will be attached to the back of the student card
        for safty reason.
      </div>
      <Grid container sx={{ marginTop: "1cm" }}>
        {[0, 1, 2, 3].map((pic, key) => (
          <Grid item xs={3} align="center" key={key}>
            {images[key] ? (
              <img
                src={images[key]}
                style={{
                  width: "3.81cm",
                  height: "5.08cm",
                  border: "1px solid #111",
                }}
                alt=""
              />
            ) : (
              <div
                style={{
                  width: "3.81cm",
                  border: "1px solid #111",
                  lineHeight: "5.08cm",
                }}
                className="sarabun"
              >
                รูปที่ {key + 1}
              </div>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function StudentInfo({ register }) {
  return (
    <div style={{ marginTop: ".5cm" }}>
      <div className="sarabun bold" style={{ fontSize: "14pt" }}>
        1. ชื่อ-นามสกุล นักเรียน
      </div>
      <div
        className="sarabun bold"
        style={{ paddingLeft: ".5cm", fontSize: "12pt" }}
      >
        Student Name-Surname
      </div>
      <div style={{ paddingLeft: ".5cm" }}>
        <Stack direction={"row"} sx={{ marginTop: ".5cm" }}>
          <div
            className="sarabun"
            style={{ fontSize: "14pt", verticalAlign: "top", width: "3.5cm" }}
          >
            ภาษาไทย :
          </div>
          <div>
            <Stack direction={"row"}>
              <div
                className="sarabun"
                style={{ fontSize: "14pt", verticalAlign: "top", width: "2cm" }}
              >
                ชื่อ
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "7cm",
                  borderBottom: "1px dotted #111",
                  textAlign: "center",
                }}
              >
                {register.first_name || "-"}
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  textAlign: "center",
                  width: "2cm",
                }}
              >
                ชื่อรอง
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "7cm",
                  borderBottom: "1px dotted #111",
                  textAlign: "center",
                }}
              >
                {register.middle_name || "-"}
              </div>
            </Stack>
            <Stack direction={"row"} sx={{ paddingTop: ".2cm" }}>
              <div
                className="sarabun"
                style={{ fontSize: "14pt", verticalAlign: "top", width: "2cm" }}
              >
                นามสกุล
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "7cm",
                  borderBottom: "1px dotted #111",
                  textAlign: "center",
                }}
              >
                {register.last_name || "-"}
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  textAlign: "center",
                  width: "2cm",
                }}
              >
                ชื่อเล่น
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "7cm",
                  borderBottom: "1px dotted #111",
                  textAlign: "center",
                }}
              >
                {register.nickname || "-"}
              </div>
            </Stack>
          </div>
        </Stack>
        <Stack direction={"row"} sx={{ marginTop: ".5cm" }}>
          <div
            className="sarabun"
            style={{ fontSize: "14pt", verticalAlign: "top", width: "3.5cm" }}
          >
            Englist :
          </div>
          <div>
            <Stack direction={"row"}>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "2.5cm",
                }}
              >
                First name
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "6.25cm",
                  borderBottom: "1px dotted #111",
                  textAlign: "center",
                }}
              >
                {register.first_name_en || "-"}
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  textAlign: "center",
                  width: "3cm",
                }}
              >
                Middle name
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "6.25cm",
                  borderBottom: "1px dotted #111",
                  textAlign: "center",
                }}
              >
                {register.middle_name_en || "-"}
              </div>
            </Stack>
            <Stack direction={"row"} sx={{ paddingTop: ".2cm" }}>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "2.5cm",
                }}
              >
                Last name
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "6.25cm",
                  borderBottom: "1px dotted #111",
                  textAlign: "center",
                }}
              >
                {register.last_name_en || "-"}
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  textAlign: "center",
                  width: "3cm",
                }}
              >
                Nickname
              </div>
              <div
                className="sarabun"
                style={{
                  fontSize: "14pt",
                  verticalAlign: "top",
                  width: "6.25cm",
                  borderBottom: "1px dotted #111",
                  textAlign: "center",
                }}
              >
                {register.nickname_en || "-"}
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
