import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Conf from "../Conf";
import {
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  Grid,
} from "@mui/material";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function OccupationalTheapist() {
  const params = useParams();
  const [form, setForm] = useState();
  const styles = {
    gridHead: {
      fontSize: "12pt",
      fontWeight: "500",
    },
    gridData: {
      fontSize: "12pt",
    },
    tdHead: {
      verticalAlign: "top",
      border: "1px solid #111",
      fontSize: "12pt",
      fontWeight: "500",
    },
    td: {
      verticalAlign: "top",
      witdh: "60%",
      border: "1px solid #111",
      fontSize: "12pt",
    },
  };
  useEffect(() => {
    function getRegister() {
      return new Promise(async (resolve) => {
        let register_id = params.id;
        let result = await axios.get(
          Conf.endpoint.tonkla + "/ca/visit/" + register_id,
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        resolve(result.data);
      });
    }
    async function getForm() {
      let register_id = params.id;
      let form = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: "therapist_interviews",
          match: { register_id: register_id },
        },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      if (form.data.result[0]) {
        form = form.data.result[0];
        form.register = await getRegister();
        setForm(form);
      }
    }
    if (params && params.id) getForm();
  }, [params]);
  useEffect(() => {
    if (form) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, [form]);
  return (
    <div>
      {form && (
        <div
          style={{
            width: "21.5cm",
            padding: "2.5cm 1cm",
            margin: "auto",
          }}
        >
          <div
            style={{ fontWeight: "500", fontSize: "14pt", textAlign: "center" }}
          >
            แบบรายงานผลการประเมินพัฒนาการโดยนักกิจกรรมบำบัด
          </div>
          <Grid container style={{ marginTop: "0.3cm" }}>
            <Grid xs={2} item style={styles.gridHead}>
              Register Code
            </Grid>
            <Grid xs={10} item style={styles.gridData}>
              : {form.register.register_code}
            </Grid>
            <Grid xs={2} item style={styles.gridHead}>
              Full name
            </Grid>
            <Grid xs={10} item style={styles.gridData}>
              : {form.register.prefix}
              {form.register.first_name} {form.register.last_name}
            </Grid>
            <Grid xs={2} item style={styles.gridHead}>
              Nickname
            </Grid>
            <Grid xs={10} item style={styles.gridData}>
              : {form.register.nickname}
            </Grid>
          </Grid>
          <TableContainer sx={{ marginTop: ".3cm" }}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...{ width: "6.5cm" }, ...styles.tdHead }}>
                    1. ข้อมูลโดยทั่วไป
                  </TableCell>
                  <TableCell sx={styles.td}>{form.general_info}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={styles.tdHead}>
                    2. ผลการประเมิน DENVER II สำหรับ 1-6 ขวบ
                  </TableCell>
                  <TableCell sx={styles.td}>
                    <div>{form.DENVER}</div>
                    <div>{form.other_DENVER}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={styles.tdHead}>
                    3. DTVP-Test สำหรับเด็ก สำหรับเด็กประถม
                  </TableCell>
                  <TableCell sx={styles.td}>
                    <div>{form.DTVP}</div>
                    <div>{form.other_DTVP}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={styles.tdHead}>
                    4. พฤติกรรมที่เห็นเป็นปัญหา
                  </TableCell>
                  <TableCell sx={styles.td}>{form.problem}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={styles.tdHead}>
                    5. โปรแกรมฝึกที่แนะนำสำหรับผู้ปกครอง
                  </TableCell>
                  <TableCell sx={styles.td}>{form.program}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={styles.tdHead}>6. สรุปผล</TableCell>
                  <TableCell sx={styles.td}>{form.conclusion}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{ fontSize: "12pt", fontWeight: "500", paddingTop: "0.3cm" }}
          >
            เพิ่มเติม
          </div>
          <div style={{ fontSize: "12pt", paddingTop: "0.2cm" }}>
            {form.note || "-"}
          </div>
        </div>
      )}
    </div>
  );
}
