import React, { useEffect, useState } from "react";
// import { BrowserRouter, Route } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Blank from "./Blank";
import Visit from "./forms/Visit";
// import Assessment from "./forms/Assessment";
import Interview from "./forms/Interview";
import VisitRegister from "./forms/VisitRegister";
// import VisitRegisterOld from "./forms/VisitRegister20220717";
import Application from "./forms/Application";
import Register from "./forms/Register";
import SelfAssessment from "./forms/self/Assessment";
import SelfRegistration from "./forms/self/SelfRegistration";
import Registration from "./forms/Registration";
import RptMonthlyStatus from "./reports/MonthlyStatus";
import RptWeeklyStatus from "./reports/WeeklyStatus";
import RptProgram from "./reports/Program";
// import VisitSelfForm from "./forms/VisitSelfForm";
// import CuratorAssessment from "./forms/CuratorAssessment";
// import WeeklyRegister from "./reports/WeeklyRegister";
import Header from "./components/Header";
import Excel from "./components/Excel";
import PrintVisitForm from "./print/VisitForm";
import PrintRegistration from "./print/Registration";
import PrintApplication from "./print/Application";
import PrintOccupationalTheapist from "./print/OccupationalTheapist";
import PrintParentsInfo from "./print/ParentsInfo";
import PrintHealthy from "./print/Healthy";
import PrintPickup from "./print/Pickup";

import VisitOverall from "./reports/VisitOverall";
import RptFactor from "./reports/Factor";
import RptAssessment from "./reports/Assessment";
import RptRegisterLevel from "./reports/RegisterLevel";

import ConfUsers from "./configurations/Users";

import DayClose from "./configurations/DayClose";

import Conf from "./Conf";

function App() {
  const [pages, setPages] = useState([]);
  const [path, setPath] = useState(null);
  const [loading, setLoading] = useState(false);
  const noHeaderList = [
    "/print/",
    "/form/visitregister",
    "/form/application",
    "/form/register/",
    "/form/self/assessment",
    "/form/self/registration",
  ];
  const getPages = async () => {
    setPages([
      {
        path: "/blank",
        component: Blank,
      },
      {
        component: Login,
        path: "/",
      },
      {
        label: "แบบฟอร์ม",
        menuId: 1,
        priority: 1,
      },
      {
        label: "เยี่ยมชม",
        menuId: 10,
        priority: 1,
        subOf: 1,
        icon: "directions_car",
        path: "/form/visit",
        component: Visit,
      },
      {
        label: "เยี่ยมชม",
        icon: "directions_car",
        path: "/form/visit/:id",
        component: Visit,
      },
      /*
      {
        label: "ประเมินการเยี่ยมชม",
        menuId: 20,
        priority: 1,
        subOf: 1,
        icon: "playlist_add_check",
        path: "/form/visitassessment",
        component: Assessment,
      },
      */
      {
        label: "ใบสมัคร",
        menuId: 20,
        priority: 1,
        subOf: 1,
        icon: "format_align_justify",
        path: "/form/staff/register",
        component: Register,
      },
      {
        label: "ใบสมัคร",
        icon: "format_align_justify",
        path: "/form/staff/register/:id",
        component: Register,
      },
      /*
      {
        label: "ประเมินการเยี่ยมชม",
        icon: "playlist_add_check",
        path: "/form/visitassessment/:register_id",
        component: Assessment,
      },
      */
      {
        label: "ประเมินพัฒนาการ",
        menuId: 30,
        priority: 1,
        subOf: 1,
        icon: "list_alt",
        path: "/form/interview/",
        component: Interview,
      },
      {
        label: "ประเมินพัฒนาการ",
        icon: "list_alt",
        path: "/form/interview/:register_id",
        component: Interview,
      },
      /*
      {
        // label: "แบบสำรวจข้อมูลเพื่อเข้ารับการสัมภาษณ์",
        label: "การสัมภาษณ์",
        icon: "list_alt",
        path: "/form/interview/:register_id/:interview_id",
        component: Interview,
      },
      */
      {
        label: "การมอบตัว",
        menuId: 40,
        priority: 1,
        subOf: 1,
        icon: "escalator_warning",
        path: "/form/registration",
        component: Registration,
      },
      {
        label: "การมอบตัว",
        icon: "escalator_warning",
        path: "/form/registration/:register_id",
        component: Registration,
      },
      // {
      //   label: "แบบประเมินผู้ปกครอง",
      //   menuId: 40,
      //   priority: 1,
      //   subOf: 1,
      //   icon: "group",
      //   path: "/form/curatorassessment",
      //   component: CuratorAssessment,
      // },
      {
        label: "รายงาน",
        menuId: 2,
        priority: 100,
      },
      {
        label: "สถานะผู้สมัคร",
        menuId: 10,
        priority: 100,
        subOf: 2,
        icon: "event_available",
        path: "/report/monthly/status",
        component: RptMonthlyStatus,
      },
      {
        label: "สถิติประจำสัปดาห์",
        menuId: 10,
        priority: 100,
        subOf: 2,
        icon: "date_range",
        path: "/report/weekly/status",
        component: RptWeeklyStatus,
      },
      // {
      //   label: "การสมัครเรียน",
      //   menuId: 20,
      //   priority: 100,
      //   subOf: 2,
      //   icon: "grid_on",
      //   path: "/report/register/weekly",
      //   component: WeeklyRegister,
      // },

      {
        label: "หลักสูตร",
        menuId: 20,
        priority: 100,
        subOf: 2,
        icon: "school",
        path: "/report/program",
        component: RptProgram,
      },
      {
        label: "ปัจจัยการสมัครเรียน",
        menuId: 20,
        priority: 100,
        subOf: 2,
        icon: "grid_on",
        path: "/report/factor",
        component: RptFactor,
      },
      {
        label: "ผลการประเมิน",
        menuId: 20,
        priority: 100,
        subOf: 2,
        icon: "playlist_add_check",
        path: "/report/assessment",
        component: RptAssessment,
      },
      {
        label: "สถานะตามระดับชั้น",
        menuId: 30,
        priority: 100,
        subOf: 2,
        icon: "grade",
        path: "/report/register/level",
        component: RptRegisterLevel,
      },
      {
        label: "ภาพรวมการเยี่ยมชม",
        menuId: 30,
        priority: 100,
        subOf: 2,
        icon: "assessment",
        path: "/report/visit/overall",
        component: VisitOverall,
      },

      {
        label: "ตั้งค่า",
        menuId: 9,
        priority: 100,
      },
      {
        label: "สิทธิการใช้งาน",
        menuId: 90,
        priority: 100,
        subOf: 9,
        icon: "person_add",
        path: "/user",
        component: ConfUsers,
      },
      {
        label: "กำหนดวันหยุด",
        menuId: 91,
        priority: 100,
        subOf: 9,
        icon: "event_busy",
        path: "/dayclose",
        component: DayClose,
      },

      {
        path: "/print/visitform/:id",
        component: PrintVisitForm,
      },
      {
        path: "/print/registration/:id",
        component: PrintRegistration,
      },
      {
        path: "/print/application/:id",
        component: PrintApplication,
      },
      {
        path: "/print/healthy/:id",
        component: PrintHealthy,
      },
      {
        path: "/print/occupationaltheapist/:id",
        component: PrintOccupationalTheapist,
      },
      {
        path: "/print/parentsinfo/:id",
        component: PrintParentsInfo,
      },
      {
        path: "/print/pickup/:id",
        component: PrintPickup,
      },

      {
        label: "นัดวันเยี่ยมชมโรงเรียน",
        alt_label: "Make an appointment for a school visit",
        icon: "event",
        path: "/form/visitregister",
        component: VisitRegister,
      },
      // {
      //   label: "นัดวันเยี่ยมชมโรงเรียน",
      //   icon: "event",
      //   path: "/form/visitregisterold",
      //   component: VisitRegisterOld,
      // },

      // {
      //   label: "แบบฟอร์มนัดวันเยี่ยมชมโรงเรียน",
      //   icon: "event",
      //   path: "/form/visitregister/:visit_id",
      //   component: VisitSelfForm,
      // },
      {
        label: "ใบสมัคร",
        path: "/form/application/:token",
        component: Application,
      },
      {
        label: "ใบสมัคร",
        path: "/form/register/:register_id",
        component: Application,
      },
      {
        label: "ประเมินการเยี่ยมชม",
        icon: "playlist_add_check",
        path: "/form/self/assessment/:schedule_id",
        component: SelfAssessment,
      },
      {
        path: "/form/self/registration/:access_token",
        component: SelfRegistration,
      },
      {
        path: "/report/excel",
        component: Excel,
      },
    ]);
    setPath(window.location.pathname);
  };
  useEffect(() => {
    getPages();
  }, []);
  function signOut() {
    localStorage.removeItem(Conf.cookie_name);
    window.location = process.env.PUBLIC_URL + "/";
  }
  function showHeader() {
    let r = true;
    for (let list of noHeaderList) {
      if (path.indexOf(list) > -1) r = false;
    }
    return r;
  }
  return (
    <div className="App">
      <BrowserRouter>
        {path && path !== "/" && showHeader() && (
          <Header
            onSignOut={signOut}
            menus={pages.filter((j) => j.menuId)}
            loading={loading}
          />
        )}
        <Routes>
          {path &&
            pages
              .filter((j) => j.component)
              .map((page, key) => (
                /*
                <Route
                  key={key}
                  path={`${process.env.PUBLIC_URL}${page.path}`}
                  exact
                  component={(props) => (
                    <page.component
                      {...props}
                      page={page}
                      onLoading={(value) => setLoading(value)}
                    />
                  )}
                />
                */

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}${page.path}`}
                  element={
                    <page.component
                      // {...props}
                      page={page}
                      onLoading={(value) => setLoading(value)}
                    />
                  }
                  key={key}
                />
              ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
