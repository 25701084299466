import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Conf from "../Conf";
import axios from "axios";
import "./styles.css";
import { Grid, Icon } from "@mui/material";
import { FieldLabel, FieldValue } from "../components/PrintField";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function ParentsInfo() {
  const params = useParams();
  const [register, setRegister] = useState();
  useEffect(() => {
    (async () => {
      if (params && params.id) {
        let result = await axios.get(
          Conf.endpoint.tonkla + "/ca/visit/" + params.id,
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        if (result.data && result.data._id) setRegister(result.data);
      }
    })();
  }, [params]);
  useEffect(() => {
    if (register) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, [register]);

  return (
    <div>
      {register && (
        <div
          style={{
            width: "21.5cm",
            padding: "2cm .6cm",
            paddingTop: "0cm",
            margin: "auto",
          }}
          className="sarabun"
        >
          <div style={{ fontSize: "18pt", textAlign: "center" }}>
            ข้อมูลด้านครอบครัว
          </div>
          <div style={{ fontSize: "14pt", textAlign: "center" }}>
            Family Information
          </div>
          <div style={{ marginTop: ".5cm" }}>
            <PersonalInfo
              register={{ ...register, type: "father" }}
              title="ข้อมูลบิดา (Father Information)"
            />
            <PersonalInfo
              register={{ ...register, type: "mother" }}
              title="ข้อมูลมารดา (Mother Information)"
            />
            <PersonalInfo
              register={{ ...register, type: "guardian" }}
              title={
                <>
                  <div>กรณีผู้ปกครองมิใช่บิดา-มารดา</div>
                  <div>Specific Guardian</div>
                </>
              }
            />
            <EmergencyInfo register={register} />

            <Stack direction={"row"}>
              <FieldLabel
                attr={{
                  label: "สถานะภาพครอบครัว",
                  alt_label: "Family Status",
                  width: "2.5cm",
                }}
              />
              <FieldValue
                attr={{
                  label: `${register.parents_married_status || "-"}`,
                  width: "19cm",
                  align: "center",
                }}
              />
            </Stack>
            <BrothersInfo brothers={register.brothers} />
          </div>
        </div>
      )}
    </div>
  );
}

function BrothersInfo({ brothers }) {
  return (
    <div style={{ paddingTop: ".5cm" }}>
      <div className="sarabun bold">
        จำนวนพี่/น้องร่วมบิดา – มารดา (ไม่รวมนักเรียน) เรียงตามลำดับ
      </div>
      {brothers.length === 0 ? (
        <div
          style={{ textAlign: "center", lineHeight: "1.2cm", fontSize: "11pt" }}
          className="sarabun"
        >
          (ไม่พบข้อมูล)
        </div>
      ) : (
        <div style={{ fontSize: "11pt", marginTop: ".2cm" }}>
          {brothers.map((brother, key) => (
            <Stack direction={"row"} sx={{ marginTop: ".1cm" }} key={key}>
              <FieldLabel
                attr={{
                  label: "ชื่อ-นามสกุล",
                  alt_label: "Full name",
                  width: "2.5cm",
                }}
              />
              <FieldValue
                attr={{
                  label: `${brother.fullname || "-"}`,
                  width: "7cm",
                }}
              />
              <FieldLabel
                attr={{
                  label: "วันเกิด",
                  alt_label: "Birth date",
                  width: "2cm",
                  align: "center",
                }}
              />
              <FieldValue
                attr={{
                  label: `${brother.birth_date || "-"}`,
                  width: "3cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{
                  label: "การศึกษา",
                  alt_label: "Degree",
                  width: "2cm",
                  align: "center",
                }}
              />
              <FieldValue
                attr={{
                  label: `${brother.degree || "-"}`,
                  width: "4.5cm",
                  align: "center",
                }}
              />
            </Stack>
          ))}
        </div>
      )}
    </div>
  );
}

function PersonalInfo({ register, title }) {
  const [person, setPerson] = useState();
  useEffect(() => {
    const type = register.type + "_";
    const fields = Object.keys(register).filter((j) => j.indexOf(type) > -1);
    let Person = {
      fullname:
        (register[type + "prefix"] || "") +
        (register[type + "first_name"] || "") +
        " " +
        (register[type + "last_name"] || ""),
      fullname_en:
        (register[type + "prefix_en"] || "") +
        (register[type + "first_name_en"] || "") +
        " " +
        (register[type + "last_name_en"] || ""),
    };
    for (let field of fields) {
      const field_name = field.replace(type, "");
      Person[field_name] = register[field];
    }
    setPerson(Person);
  }, [register]);

  return (
    <div>
      {person && (
        <>
          <Grid container sx={{ margin: ".5cm auto .2cm" }}>
            <Grid item xs={6} className="sarabun bold">
              {title}
            </Grid>
            <Grid item xs={6} className="sarabun" align="right">
              {register.type !== "guardian" && (
                <Stack direction={"row"} spacing={1} style={{ width: "3.3cm" }}>
                  {person.is_curator === true ? (
                    <Icon>done</Icon>
                  ) : (
                    <Icon>check_box_outline_blank</Icon>
                  )}
                  <div style={{ fontSize: "11pt" }}>เป็นผู้ปกครอง</div>
                </Stack>
              )}
              {person.is_curator}
            </Grid>
          </Grid>
          <Stack direction={"row"}>
            <FieldLabel
              attr={{
                label: "ชื่อ-นามสกุล",
                alt_label: "Full name",
                width: "2.5cm",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.fullname || "-"} (${
                  person.fullname_en || "-"
                })`,
                width: "19cm",
                align: "center",
              }}
            />
          </Stack>
          <Stack direction={"row"} sx={{ marginTop: ".1cm" }}>
            <FieldLabel
              attr={{
                label: "สัญชาติ",
                alt_label: "Nationality",
                width: "2.5cm",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.nationality || "-"}`,
                width: "4.5cm",
                align: "center",
              }}
            />
            <FieldLabel
              attr={{
                label: "เชื้อชาติ",
                alt_label: "Origin",
                width: "2cm",
                align: "center",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.origin || "-"}`,
                width: "5cm",
                align: "center",
              }}
            />
            <FieldLabel
              attr={{
                label: "ศาสนา",
                alt_label: "Religion",
                width: "2cm",
                align: "center",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.religion || "-"}`,
                width: "5cm",
                align: "center",
              }}
            />
          </Stack>
          <Stack direction={"row"} sx={{ marginTop: ".1cm" }}>
            <FieldLabel
              attr={{
                label: "หมายเลขโทรศัพท์",
                alt_label: "Phone Number",
                width: "3cm",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.phone || "-"}`,
                width: "4cm",
                align: "center",
              }}
            />
            <FieldLabel
              attr={{
                label: "อีเมล",
                alt_label: "Email",
                width: "2cm",
                align: "center",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.email || "-"}`,
                width: "5cm",
                align: "center",
              }}
            />
            <FieldLabel
              attr={{
                label: "อาชีพ",
                alt_label: "Job",
                width: "2cm",
                align: "center",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.job || "-"}`,
                width: "5cm",
                align: "center",
              }}
            />
          </Stack>
          <Stack direction={"row"} sx={{ marginTop: ".1cm" }}>
            <FieldLabel
              attr={{
                label: "สถานที่ทำงาน",
                alt_label: "Workplace",
                width: "3cm",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.workplace || "-"}`,
                width: "8cm",
                align: "center",
              }}
            />
            <FieldLabel
              attr={{
                label: "เงินเดือน",
                alt_label: "Salary",
                width: "2cm",
                align: "center",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.salary || "-"}`,
                width: "3cm",
                align: "center",
              }}
            />
            <FieldLabel
              attr={{
                label: "สภานภาพ",
                alt_label: "Status",
                width: "2cm",
                align: "center",
              }}
            />
            <FieldValue
              attr={{
                label: `${person.status || "-"}`,
                width: "3cm",
                align: "center",
              }}
            />
          </Stack>
        </>
      )}
    </div>
  );
}

function EmergencyInfo({ register }) {
  return (
    <div style={{ paddingTop: ".5cm" }}>
      <div className="sarabun bold">
        การติดต่อหากเกิดเหตุกรณีฉุกเฉินหรือเร่งด่วน
        (หากไม่สามารถติดต่อผู้ปกครองที่แจ้งไว้ข้างบนได้)
      </div>
      <div className="sarabun">
        Emergency contact (in case the school cannot contact parents)
      </div>

      <Stack direction={"row"} sx={{ marginTop: ".3cm" }}>
        <FieldLabel
          attr={{
            label: "ชื่อ-นามสกุล",
            alt_label: "Full name",
            width: "2.5cm",
          }}
        />
        <FieldValue
          attr={{
            label: `${register.other_contact_prefix || ""} ${
              register.other_contact_first_name || "-"
            } ${register.other_contact_last_name || ""} (${
              register.other_contact_relation || "-"
            })`,
            width: "19cm",
            align: "center",
          }}
        />
      </Stack>
      <Stack direction={"row"} sx={{ marginTop: ".1cm" }}>
        <FieldLabel
          attr={{
            label: "หมายเลขโทรศัพท์",
            alt_label: "Phone number",
            width: "3cm",
          }}
        />
        <FieldValue
          attr={{
            label: `${register.other_contact_phone || "-"}`,
            width: "7.5cm",
            align: "center",
          }}
        />
        <FieldLabel
          attr={{
            label: "หมายเลขโทรศัพท์มือถือ",
            alt_label: "Cell number",
            width: "4cm",
            align: "center",
          }}
        />
        <FieldValue
          attr={{
            label: `${register.other_contact_mobile || "-"}`,
            width: "7cm",
            align: "center",
          }}
        />
      </Stack>
    </div>
  );
}
