import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Conf from "../../Conf";
import axios from "axios";
// import TextField from "@mui/material/TextField";

import {
  GridSelectItem,
  GridInputItem as GridInput,
} from "../../components/InputForm";

const styles = {
  title: { fontWeight: 400, padding: "13px 15px", fontSize: "1rem" },
  subTitle: {
    color: "#888",
    fontSize: ".9rem",
    paddingTop: "3px",
    paddingLeft: "13px",
  },
  questionTitle: { padding: "5px 15px", fontSize: "1rem" },
};
export default function FormPickupPerson({
  form,
  state,
  changeForm,
  changeFields,
}) {
  function changeValue(e, key) {
    let Form = JSON.parse(JSON.stringify(form));
    let id = e.target.id;
    let value = e.target.value;
    Form[key][id] = value;
    changeForm(Form);
  }
  function changeFile(file, key) {
    let Form = JSON.parse(JSON.stringify(form));
    Form[key]["file"] = file;
    changeForm(Form);
  }
  return (
    <div>
      {form && (
        <>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              align="center"
              component="h1"
              style={{ fontSize: "1.5rem" }}
            >
              ผู้ปกครองรับส่งนักเรียน
              <div style={{ color: "#888", fontSize: "1rem" }}>
                Student pick up persons
              </div>
            </Grid>
            <Grid item xs={12}>
              {form.map((person, key) => (
                <PersonForm
                  key={key}
                  no={key}
                  title={`บุคคลที่ ${key + 1}`}
                  onChange={(e) => changeValue(e, key)}
                  changeFile={(file) => changeFile(file, key)}
                  person={person}
                  state={state}
                />
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

function PersonForm({ person, title, onChange, changeFile, no, state }) {
  const [img, setImg] = useState();
  useEffect(() => {
    (async () => {
      if (person.file) {
        setImg(person.file);
      } else if (person.photo) {
        let img = await axios.get(
          Conf.endpoint.fileUpload + "/" + person.photo
        );
        setImg(img.data);
      }
    })();
  }, [person]);

  function changeImage(e) {
    var file = e.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        let size = calc_image_size(reader.result);
        if (size < 500) {
          changeFile(reader.result);
          setImg(reader.result);
        } else {
          alert("Image file must smaller than 500KB");
        }
      };
    }
  }

  function calc_image_size(image) {
    let y = 1;
    if (image.endsWith("==")) {
      y = 2;
    }
    const x_size = image.length * (3 / 4) - y;

    return Math.round(x_size / 1024);
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <h3 style={styles.title}>{title}</h3>
        </Grid>
        <Grid item xs={2} align="right" style={{ paddingTop: "30px" }}>
          <label htmlFor={"person-img-" + no}>
            <input
              accept="image/png,image/jpeg,image/PNG,image/JPEG"
              id={"person-img-" + no}
              type="file"
              onChange={changeImage}
              style={{ display: "none" }}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <Icon>photo_camera</Icon>
            </IconButton>
          </label>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              {img || person.photo ? (
                <img
                  // src={img || "https://tca.tonkla.ac.th/upload/" + person.photo}
                  src={img}
                  alt=""
                  style={{
                    border: "1px solid #ccc",
                    width: "85%",
                    maxHeight: "200px",
                  }}
                />
              ) : (
                <Icon style={{ fontSize: "6.5rem", color: "#999" }}>
                  person
                </Icon>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Grid container spacing={2}>
                <GridSelectItem
                  size={[12, 6, 3, 3]}
                  label="Prefix"
                  id="prefix"
                  options={state.prefixs}
                  changeValue={onChange}
                  // onChange={(e) => console.log(e)}
                  value={person.prefix}
                />
                <GridInput
                  size={[12, 6, 3, 3]}
                  label="First name"
                  id="first_name"
                  placeholder="ชื่อ"
                  changeValue={onChange}
                  value={person.first_name}
                />
                <GridInput
                  size={[12, 6, 3, 3]}
                  label="Middle name"
                  placeholder="ชื่อกลาง"
                  id="middle_name"
                  changeValue={onChange}
                  value={person.middle_name}
                />
                <GridInput
                  size={[12, 6, 3, 3]}
                  label="Last name"
                  placeholder="นามสกุล"
                  id="last_name"
                  changeValue={onChange}
                  value={person.last_name}
                />
                <GridInput
                  size={[12, 6, 6, 6]}
                  label="Relation"
                  placeholder="ความสัมพันธ์"
                  id="relation"
                  changeValue={onChange}
                  value={person.relation}
                />
                <GridInput
                  size={[12, 6, 6, 6]}
                  label="Phone Number"
                  placeholder="เบอร์โทรศัพท์"
                  id="phone_number"
                  changeValue={onChange}
                  value={person.phone_number}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
